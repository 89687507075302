<template>
  <a-date-picker
    v-bind="record.options"
    :value="value"
    :value-format="valueFormat"
    :getCalendarContainer="record.options.getCalendarContainer || (triggerNode => triggerNode.parentNode)"
    @change="change"
    @openChange="openChange"
    @panelChange="panelChange"
    @ok="ok"
  />
</template>

<script>
import formEleMixin from '../form-ele-mixin'
import mixin from './mixin'
export default {
  name: 'AxDatePicker',
  mixins: [formEleMixin, mixin],
  data () {
    return {
      dateFormat: 'YYYY-MM-DD'
    }
  },
  methods: {
    /**
     * 点击确定按钮的回调（showtime为true会出现ok按钮）
     * 点击确定的时候date肯定有数据，不需要判空
     * @param date
     */
    ok (date) {
      this.$emit('ok', date, this.record.model)
    }
  }
}
</script>

<!--
未设置属性：
mode
open
-->
